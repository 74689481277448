import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "gatsby"
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import StorefrontIcon from '@material-ui/icons/Storefront';
import Typography  from '@material-ui/core/Typography';

import TransitionAlert from './transition-alert';
import { useSearch } from './search-context';
import { EP, callApi } from '../api';
import Loading from './loading';
import DialogButton from './dialog-button';
import useFetchStores from './use-fetch-stores';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  alert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  noItems: {
    padding:  theme.spacing(8, 0),
    color: theme.palette.text.secondary,
    textAlign: 'center',
    display: 'block',
  }
}));

const sortStoresByName = (a, b) => {
  if (a.name < b.name)
    return -1;
  if (a.name > b.name)
    return 1;
  return 0;
}

const getStoresFiltered = (stores, searchTerm) => {
  const re = new RegExp(searchTerm.toLowerCase());
  return stores
    .filter(s => re.test(JSON.stringify([
      s.id,
      s.name,
      s.addressLine1,
      s.addressLine2,
    ]).toLowerCase()))
    .sort(sortStoresByName);
}

const StoresList = () => {
  const { t } = useTranslation('storesList');
  const classes = useStyles();
  const initialDeleteStore = { isDeleting: false, error: null, isSuccess: false };
  const [deleteStore, setDeleteStore] = useState(initialDeleteStore);
  const [searchTerm] = useSearch();
  const { stores, isLoading, isError, setStores } = useFetchStores();
  const storesFiltered = getStoresFiltered(stores, searchTerm);
  if (isLoading) return <Loading />
  if (isError) return (
    <TransitionAlert severity="error" isOpen>
      {t('errorFetch')}
    </TransitionAlert>
  );

  const handleDelete = async (storeId) => {
    try {
      setDeleteStore(initialDeleteStore);
      await callApi(EP.stores.delete, { storeId });
      setStores(stores.filter(store => store.id !== storeId));
      setDeleteStore({...initialDeleteStore, isSuccess: true });
    } catch (error) {
      const message = (error.response && error.response.data && error.response.data.message)
        || t('errorDelete'); 
      setDeleteStore({ ...initialDeleteStore, error: message });
    }       
  }

  const handleCloseDeleteAlert = () => setDeleteStore(initialDeleteStore);

  return (
    <div>
      { deleteStore.isSuccess && (
        <TransitionAlert severity="success" isOpen className={classes.alert} onClose={handleCloseDeleteAlert}>
          {t('deleteSuccess')}
        </TransitionAlert>
      )}
      { deleteStore.error && (
        <TransitionAlert severity="error" isOpen className={classes.alert} onClose={handleCloseDeleteAlert}>
          { deleteStore.error }
        </TransitionAlert>
      )}
      { !storesFiltered.length && <Typography className={classes.noItems}>{t('noStores')}</Typography> }
      <List className={classes.root}>
      { storesFiltered.map(store => (
        <React.Fragment key={store.id}>
          <ListItem alignItems="flex-start" button component={Link} to={`/stores/edit?id=${store.id}`}>
            <ListItemAvatar>
              <Avatar>
                <StorefrontIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`${store.name}`}
              secondary={
                <React.Fragment>
                  {`@${store.id}`}
                </React.Fragment>
              }
            />
            <ListItemSecondaryAction>
              <DialogButton
                ariaLabel="delete"
                edge="end"
                type="icon"
                title={t('deleteStore', { id: store.id })}
                description={t('confirmDelete', { id: store.id, name: store.name })}
                onConfirm={() => handleDelete(store.id)}
              >
                 <DeleteIcon />
              </DialogButton>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider variant="inset" component="li" />
        </React.Fragment>
      ))}
    </List>
    </div>
  );
}

export default StoresList;
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

function SimpleDialog(props) {
  const { t } = useTranslation('common');
  const { 
    description,
    onClose, 
    onConfirm, 
    open, 
    title, 
   } = props;

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} fullWidth color="primary">
            {t('dismiss')}
          </Button>
          <Button onClick={handleConfirm} fullWidth color="primary" autoFocus>
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
  );
}

SimpleDialog.propTypes = {
  description: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default function DialogButton(props) {
  const {
    ariaLabel,
    color,
    children, 
    className,
    description, 
    edge,
    onConfirm,
    variant,
    title, 
    type,
  } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={className}>
      { type === 'icon' && (
        <IconButton edge={edge} aria-label={ariaLabel} onClick={handleClickOpen}>
          {children}
        </IconButton>
      )}
      { !type && (
        <Button variant={variant} color={color} fullWidth onClick={handleClickOpen}>
          {children}
        </Button>
      )}
      <SimpleDialog 
        open={open} 
        onClose={handleClose} 
        onConfirm={onConfirm}
        title={title}
        description={description}
      />
    </div>
  );
}

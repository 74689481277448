import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "gatsby"
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import useAuth from '../components/use-auth';
import Layout from '../components/layout';
import SEO from '../components/seo';
import StoresList from '../components/stores-list';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(4),
  },
}));

const StoresPage = ({
  path
}) => {
  const { t } = useTranslation('storesList');
  const auth = useAuth();
  const classes = useStyles();
  return (
    <Layout auth={auth} path={path}>
      <SEO title="Stores" />
      { auth && (
        <div>
          <Button 
              className={classes.button}
              component={Link}
              to="/stores/add" 
              fullWidth  
              variant="contained"
              color="primary"
            >
                {t('addStore')}
            </Button>
          <StoresList />
        </div>
      )}
    </Layout>
  )
}

export default StoresPage;
